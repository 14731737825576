const newsAccordion = [
  {
    title: 'Infectious Diseases',
    image: 'images/news.png',
  },
  {
    title: 'Covid',
    image: 'images/cov-inf-news.jpg',
  },
  {
    title: 'General',
    image: 'images/old-news.jpg',
  },
];
const heroNews = 'News';
export { heroNews, newsAccordion };
