const careerOpp = [
  {
    description:
      "We are actively looking for Infectious Diseases Physicians and APN's. Please send your CV to ",
    mailto: 'idadvanced@gmail.com',
    image: 'images/career.png',
  },
];

const heroCareer = 'Career Opportunities';

export { heroCareer, careerOpp };
