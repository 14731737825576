const insComp = [
  {
    alt: 'Insurance Accepted',
    image: 'images/ppl.jpg',
    title: 'Following are the Companies for which we accept Insurance',
    description:
      'If you have any questions on the insurance, please contact 732-230-2828 between 9.30 AM to 5.30 PM on Monday-Friday (or) please send an email to ',
    mailto: 'idadvanced.office@gmail.com',
  },
];

const heroIns = 'Insurance';

export { heroIns, insComp };
