const insCompOne = [
  '1199 National Benefit Dund',
  'AARP',
  'Active Health',
  'Aetna',
  'Aetna Golden Medicare',
  'Alicare Inc.',
  'Teams Ters C/O Crossroads Health',
  'The Mail Handlers Benefit Plan',
  'Operating Engineers Local 825',
  'Principal Life Ins Co',
  'United Healthcare Community Plan',
  'Ameri Health processing center',
  'United Healthcare Community Plan',
  'American Progressive Med',
  'APWU Health Plan',
  'CHAMPVA',
  'Horizon BlueCross BlueShield',
];
const insCompTwo = [
  'Cigna Health Care',
  'Corsource',
  'Medicaid',
  'Oxford',
  'Qualcare',
  'Unified Lie Insurance Company',
  'Coventry Health Care',
  'Empire Medicare Service',
  'Golden  Rule Ins',
  'Great West Health Plan',
  'Health Care Partners',
  'Health First TPA',
  'Healthfirst',
  'Humana Claims',
  'Meritain Health',
  'Rail Road Medicare Services',
  'Secure Horizon',
];
const insCompThree = [
  'Wellcare',
  'IDA',
  'Fiserv Health',
  'GHI',
  'Local 804 WTF Magnacare',
  'Local Union',
  'Local 282 Welfare Trust Fund',
  'Local 825',
  'Local 1245 Health Fund',
  'Nipon Life Insurance Co of America',
  'PHCS',
  'Unicare',
  'Magnacare',
];
export { insCompOne, insCompTwo, insCompThree };
