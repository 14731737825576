const testiCred = [
  {
    alt: 'Testimonials',
    image: 'images/feedback.jpg',
  },
];

const feedbackImg = [
  {
    title: 'From Healthgrades.com',
    image: 'images/feedback1.jpg',
    align: 'left',
  },
];
const heroTestimonials = 'Testimonials from Our Patients';
export { heroTestimonials, feedbackImg, testiCred };
